import React from 'react'

export default class Label extends React.Component {
    render() {
        const style = this.props.textArea ? {height:"50px"} : {}
        return (
            <label htmlFor={"floatingInput_"+this.props.nome} className="d-flex align-items-center fs-13px" style={style}>
                {this.props.label}
            </label>
        )
    }
}