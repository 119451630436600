import React from 'react'
import { AppSettings } from '../config/app-settings.js'
import { Link } from 'react-router-dom'
import { getBanners, getCategorias } from '../store/api.js'

export default class CustomerOrder extends React.Component {
	static contextType = AppSettings
	
	constructor(props) {
		super(props)

		this.state = {
			categorias:[],
			brandData: null,
			banners: []
		}
	}
  
	async fetchData(){
		const items = await getBanners()
		const categData = await getCategorias()
		const brandData = this.context.brand

		const categorias = categData.filter((e) => {
			let result = false

			if ( e.produto.length > 0 ) result = true

			e.categorias.forEach((c) => {
				if ( c.produto.length > 0 ) result = true
			})

			return result
		})

		this.setState({ banners: items, categorias: categorias, brandData:brandData })
	}

	async componentDidMount() {
		await this.fetchData()
	}
  
	render() {
		const brand = this.state.brandData
		const img = brand && brand.loja.imagem && brand.loja.imagem.imagem ? brand.loja.imagem.imagem : brand && brand.imagem_perfil ? brand.imagem_perfil.imagem : ""

		return (
			<div className="vh-100" >
				<div className='pos pos-customer' id="pos-customer"  >
					<div className="pos-menu">
						<div className="logo">
							{this.state.brandData && (
								<Link to={this.state.brandData.loja.b_home ? "/home" : "/"}>
									<div className="logo-img"><img alt="" style={{borderRadius: "10px"}} src={"https://grubupload.s3.amazonaws.com/"+img} /></div>
									<div className="logo-text" style={{textAlign: "center", textTransform: "uppercase"}}>{this.state.brandData.nome_fantasia}</div>
								</Link>
							)}
						</div>
						<div className="nav-container">
							<div className="height-full">
								<ul className="nav nav-tabs">
									<li key="categ_1000" className="nav-item">
										<Link to="/destaques" className={"nav-link active"} style={{height: "100%"}} >
											<i className="fa fa-solid fa-star"></i>DESTAQUES
										</Link>
									</li>
									<li key="categ_0" className="nav-item">
										<Link to={ localStorage.getItem('intendto') } className={"nav-link"}>
											<i className="fa fa-fw fa-utensils mr-1 ml-n2"></i>TODOS
										</Link>
									</li>
									
									{this.state.categorias && this.state.categorias.map((item,i) =>
										<li key={"categ_"+item.id} className="nav-item">
											<Link to={localStorage.getItem('intendto')+"?filtro="+item.id} className={"nav-link"}>
												<img alt="categorias" src={"https://grubicons.s3.us-east-1.amazonaws.com/"+item.icon} className="fa fa-fw mr-1 ml-n2"/> {item.nome.toUpperCase()}
											</Link>
										</li>
									)}
								</ul>
							</div>
						</div>
					</div>
					<div className="pos-content">
						<div className="gallery" style={{height:'100%', margin: '10px', overflow: "auto"}}>
						
							{this.state.banners.map( (i) => {
								if (i) {
									const img = i.imagem
									const path = img ? 'https://grubupload.s3.amazonaws.com/'+img.imagem : ''
							
									return (
										<div key={"pos_"+i.id} className="image gallery-group-1" style={{float: "left", height: "550px", margin: "20px 0"}}>
												<div className="img" style={{backgroundImage: "url("+path+")", height:"100%", borderRadius: "10px"}}></div>
										</div>
									)
								}
							})}	

						</div>
					</div>
				</div>			
			</div>
		)
	}
}