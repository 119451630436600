
import React from 'react'

import {minhasComanda} from './../../store/api.js'

import { AppSettings } from '../../config/app-settings.js'

export default class Pedidos extends React.Component {
    static contextType = AppSettings

    constructor(props) {
		super(props)

		this.state = {
			meuHistorico: []
		}
	}

	async componentDidMount() {
		const meuHistorico = await minhasComanda(this.context.client)
		this.setState({ meuHistorico:meuHistorico})
        this.props.loading(false)
	}

    getDate(date){
		const dt = new Date(date)
		const months = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro']

		return dt.getDate()+" "+months[dt.getMonth()]+" "+dt.getFullYear()
	}

    async showDetalhe(comanda){
        this.context.sethistoricoCarrinho(comanda)
        this.context.atualizaCarrinho([])
        this.props.setDetalhe()
        this.props.loading(true)
	}

    render() {
        const meuHistorico = this.state.meuHistorico
        return (
            <>
                {meuHistorico && (
                  <div className="pos-sidebar" id="pos-sidebar">
                    <div className="pos-sidebar-header" style={{backgroundColor: "#0078FF"}}>
                        <div className="title"> Meus Pedidos </div>
                    </div>

                    <div className="pos-sidebar-body tab-content" style={{padding:"30px 15px"}}>

                        {meuHistorico && meuHistorico.map( (c) => {
                            return (
                                <div className="widget-card rounded mb-20px col-12" onClick={() => this.showDetalhe(c)}>
                                    <div className="widget-card-cover rounded" style={{background:"#e9ecef"}}></div>
                                    <div className="widget-card-content">
                                        <h4 className="mb-10px"><b>Pedido #{c.id}</b></h4>
                                        <div className="row">
                                            <div className="align-items-left col-8" style={{fontSize:"15px"}}>
                                                {(c.status === 'entregue' || c.status === 'fechada') && (
                                                    <b className="badge border border-green text-green rounded">Pedido entregue</b>
                                                )}
                                                {c.status !== 'entregue' && c.status !== 'fechada' && c.status !== 'cancelada' && c.status !== 'producao' && (
                                                    <b className="badge border border-primary text-primary rounded">Pedido a caminho</b>
                                                )}
                                                {c.status === 'cancelada' && (
                                                    <b className="badge border border-danger text-danger rounded">Pedido cancelado</b>
                                                )}
                                                {c.status === 'producao' && (
                                                    <b className="badge border border-primary text-primary rounded">Pedido em preparo</b>
                                                )}
                                            </div>
                                            <div className="col-4 align-items-right"  style={{textAlign: "right", fontSize:"15px"}}><p>{c.pedidos.length} itens</p></div>
                                        </div>
                                        
                                    </div>
                                    <div className="widget-card-content bottom row">
                                        <div className="col-6 align-items-left">
                                            <b className="text-body" style={{fontSize:"15px"}}>{this.getDate(c.updated_at)}</b>
                                        </div>
                                        <div className="col-6 align-items-right" style={{textAlign: "right", color:"#0078FF", fontSize:"15px"}}>
                                            <b className="text-body text-primary" style={{color:"#0078FF"}} >Ver detalhe</b>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
                )}
            </>
        )
    }
}