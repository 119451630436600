import React from 'react'
import InputMask from 'react-input-mask';

export default class InputTextMask extends React.Component {
    constructor(props) {
        super(props)

        this.handleChangeData = this.handleChangeData.bind(this)
    }

    handleChangeData = (e) => {
        e.preventDefault()

        this.props.setFomrData(e.target.name, e.target.value)
    }

    render() {
        return (
            <InputMask 
                id={"floatingInput_"+this.props.nome} 
                name={this.props.nome} 
                value={this.props.dadosIniciais} 
                className="form-control fs-15px" 
                onChange={(e) => this.handleChangeData(e)} 
                required={this.props.required && ("required")}
                maxLength={this.props.maxlength && (this.props.maxlength)}
                placeholder={this.props.nome}
                mask={this.props.mask}
            />
        )
    }
}