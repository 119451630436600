import React from 'react'
import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'

import { AppSettings } from './config/app-settings.js'
import Content from './components/content/content'
import QRcode from './pages/qrcode'

import { getHomeBanner, getBrandData, getComanda, getHistoricoCarrinho, getComandaDelivery, getMesa } from './store/api.js'

export default class App extends React.Component {

	constructor(props) {
		super(props)

		const url = window.location.pathname.toLocaleLowerCase()
		
		this.handleSetPixel = (value) => {
			this.setState(state => ({
				fbPixel: value
			}));
		}

		this.handleSetAppMesa = (value) => {
			this.setState(state => ({
				mesa: value
			}));
		}

		this.handleTema = (value) => {
			this.setState(state => ({
				appTheme: value
			}));
		}

		this.handleSetAppQR = (value) => {
			this.setState(state => ({
				qrcode: value
			}));
		}

		this.handleSetComanda = (value) => {
			this.setState(state => ({
				comanda: value
			}));
		}

		this.handleSetHome = (value) => {
			let banner = null

			if (value){
				getHomeBanner().then((r) => {
					banner = r.data

					this.setState(state => ({
						banner: banner
					}));
				})
			}

			this.setState(state => ({
				home: value
			}));
		}

		this.addCarrinho = (produto) => {
			const items = this.state.itensCarrinho
			items.push(produto)
	
			this.setState({ itensCarrinho: items })
		}

		this.atualizaCarrinho = (arrPordutos) => {
			this.setState({ itensCarrinho: arrPordutos })
		}

		this.setClient = (token) => {
			this.setState({ client: token })
		}
		this.sethistoricoCarrinho = (comanda) => {
			this.setState({ historicoCarrinho: comanda })
		}

		this._isMounted = false

		this.state = {
			appTheme: 'themaLine',
			mesa:null,
			mesaData:null,
			qrcode:false,
			comanda:null,
			screenSize: null,
			fbPixel:null,
			home:false,
			banner:null,
			brand:null,
			open: false,
			delivery: url.includes('delivery') ? true : false,
			dinein: url.includes('dinein') ? true : false,
			carrinho: url.includes('dinein') || url.includes('delivery') ? true : false,
			quantidade:1,
			itensCarrinho: [],
			client:null,
			historicoCarrinho:null,

			handleTema: this.handleTema,
			handleSetAppMesa: this.handleSetAppMesa,
			handleSetAppQR: this.handleSetAppQR,
			handleSetPixel: this.handleSetPixel,
			handleSetHome: this.handleSetHome,
			handleSetComanda: this.handleSetComanda,
			addCarrinho: this.addCarrinho,
			atualizaCarrinho: this.atualizaCarrinho,
			setClient: this.setClient,
			sethistoricoCarrinho: this.sethistoricoCarrinho,
		}
	}

	isOpen(brandData){
		const today = new Date()
		const weekday = ["0x0000001","0x0000010","0x0000100","0x0001000","0x0010000","0x0100000","0x1000000"]

		if (brandData.loja.horarios){
			brandData.loja.horarios.forEach(element => {
				let month = today.getMonth()+1
				month = month < 10 ? "0"+month : month
				const binario = '0x'+(element.dias >>> 0).toString(2).padStart(7, 0)
				const hi = new Date( month+"/"+today.getDate()+"/"+today.getFullYear()+" "+element.horario_inicial )
				const hf = new Date( month+"/"+today.getDate()+"/"+today.getFullYear()+" "+element.horario_final )

				if(!!(weekday[today.getDay()] & binario) ){
					if (today > hi && today < hf){
						this.setState({open: true})
					}
				}
			})
		}
	}

	async validaTipoCardapio() {
		const path = window.location.pathname.split("/")
		const brand = JSON.parse(localStorage.getItem('brand'))
		
		if(path.length === 2 && path[1].includes('dinein')){

			this.setState({qrcode: true})

		} else if(path.length === 3 && path[1].includes('dinein')) {

			const mesaData = await getMesa(path[2])
			
			if (mesaData.data.status === 'fechada' || !brand.data.loja.b_pedidos){
				window.location.pathname = "/404"
			}

			if (mesaData && mesaData.data) {
				const comanda = await getComanda({mesa:path[2]})
				const historico = await getHistoricoCarrinho(comanda.data.id)
				this.setState({mesa: path[2], comanda:comanda.data, mesaData: mesaData.data, historicoCarrinho:historico.data })
			} else {
				console.log("mesa nao encontrada")
			}

		} else if( path[1].includes('delivery')) {
			if (!brand.data.loja.b_delivery){
				window.location.pathname = "/404"
			}

			const comanda = await getComandaDelivery()
			this.setState({comanda:comanda.data})
		}
	}

  	async componentDidMount() {
		const path = window.location.pathname.split("/")
		
		if (!this._isMounted) {
			const brandData = await getBrandData()
			const pixel = brandData.loja.fb_pixel
			const gtm = brandData.loja.gtm
			const tema = brandData.loja.tema
			const token = JSON.parse(localStorage.getItem('cliente'))

			if (this.state.delivery || this.state.carrinho){
				if (this.state.delivery) {
					localStorage.setItem( "intendto", "/delivery" )
				} else {
					localStorage.setItem( "intendto", "/dinein/"+ path[path.length-1])
				}
			} else {
				localStorage.setItem( "intendto", "/")
			}

			if (pixel) {
				ReactPixel.init(pixel)
				ReactPixel.pageView()
			}

			if(gtm){
				TagManager.initialize(gtm)
			}

			this.isOpen(brandData)

			if (token) {
				this.setState({client: token})
			}
			
			this.setState({appTheme:tema})
			this.setState({screenSize: window.innerWidth >= 992 ? "tablet" : "mobile"})
			this.setState({ brand:brandData })
			document.title = brandData.nome_fantasia+' - GRUB - Restaurante Inteligente'

			await this.validaTipoCardapio()
		}

		this._isMounted = true
	}

  render() {
		const tema = this.state.appTheme
		const home = this.state.home ? "home" : ""

		return (
			<>
				{this.state.brand && (
					<AppSettings.Provider value={this.state}>
						{this.state.qrcode ? (
							<QRcode />
						) : (
							<div className={ 'app  app-without-sidebar app-without-header app-content-full-height has-scroll ' + tema + ' ' + home}>
								{this.state.home && this.state.banner && (
									<div className="banner" style={{backgroundImage: "url(https://grubupload.s3.amazonaws.com/"+this.state.banner.imagem+")"}}></div>	
								)}

								<Content />
							</div>
						)}
					</AppSettings.Provider>
				)}
			</>
		)
	}
}