import React from 'react'
import { Link } from 'react-router-dom'
import { AppSettings } from '../../config/app-settings.js'
import InputText    from '../atoms/InputText'
import Label        from '../../components/atoms/Label'
import Cartao from '../molecula/cartao.jsx'
import Endereco from '../molecula/endereco.jsx'
import Offcanvas 	from 'react-bootstrap/Offcanvas'

import {addVoucheComanda, addEndereco, getCodigoInfo, me, getEntrega, getComandaDelivery, analise, confirmaPagamento, addPedido, addDadosEntrega, pagamento, getCodigoPix, validaStatusPagameto} from './../../store/api.js';

import Lottie from "lottie-react"
import lottieSegunraca from "../../assets/seguranca.json"

import '@fortawesome/fontawesome-free/css/all.css';

export default class Carrinho extends React.Component {
    static contextType = AppSettings

    constructor(props) {
        super(props)

        this._isMounted = false

        this.state = {
            codigoDesconto: null,
            possuiEntrega: null,
            entregaDisponivel: false,
            cupomDesconto:null,
            showCartao:false,
            showEndereco:false,
            loading:false,
            hasCard: false,
            abaCartao: true,
            chavePIX: false,
            tipoPagamento: 'CREDITO',
            clock: "6:00",
            timer: 300,
            timerInterval: null,
            error:null,
            card:{
				cvc: '',
				expiry: '',
				focus:'number',
				name: '',
				number: '',
				token: '',
				cpf:''
			},
        }

        this.setDesconto = this.setDesconto.bind(this)
        this.submitCard = this.submitCard.bind(this)
        this.submitEndereco = this.submitEndereco.bind(this)
        this.mudaEndereco = this.mudaEndereco.bind(this)
    }

    async componentDidMount() {
        this._isMounted = true
		this._isMounted && await this.reloadUser()
        this.context.sethistoricoCarrinho(null)
        this.props.loading(false)
	}

    componentWillUnmount() {
        this._isMounted = false;
    }

    async submitCard(newcard){
		const card = newcard

		if (card.number && card.number.length >= 15) {
			card.creditCardNumber = card.number.substring(15)
			card.creditCardToken = null

			this.setState({showCartao: false, card: card, hasCard:true})
		}

        if (card.creditCardNumber && card.creditCardNumber.length >= 4){
            this.setState({showCartao: false, card: card, hasCard:true})
        }
	}

    async submitEndereco(novoEnd){

		if (novoEnd.items.rua && novoEnd.items.rua.length > 3) {
			const endereco = await addEndereco(novoEnd)

			if (endereco.data && endereco.data.id) {
				novoEnd.numero = endereco.data.numero

				this.setState({ possuiEntrega: novoEnd, showEndereco: false, entregaDisponivel: true })
			}
			this.reloadUser()
		}
	}

    async mudaEndereco(novoEnd){
		if (novoEnd.rua && novoEnd.rua.length > 3) {
			this.setState({ possuiEntrega: novoEnd, showEndereco: false, entregaDisponivel: true })
		}
	}

    async reloadUser(){
		const token = JSON.parse(localStorage.getItem('cliente'))
		const usuario = await me({items: {token: token}})

		if (token) this.setState({cliente: token})

		if (usuario.data.usuario) {
			const dataUser = usuario.data.usuario
			const enderecos = usuario.data.enderecos
			let card = this.state.card

			if (enderecos.length > 0) {
				const endereco = enderecos[0]
				const entrega  = await getEntrega(endereco.lat, endereco.lng)
	
				if (entrega) {
					endereco.id_zona = entrega.id
					endereco.distancia = entrega.km
					endereco.tempo = entrega.tempo
					endereco.valor = entrega.valor

					localStorage.setItem('possuiEntrega', JSON.stringify(endereco))
	
					this.setState({enderecos:enderecos, possuiEntrega: endereco, entregaDisponivel: true})
				} else {
					this.setState({ possuiEntrega: endereco, entregaDisponivel: false})
				}
			}
		
			if (dataUser){
				if (dataUser.cards && dataUser.cards.length > 0 ){
					card.creditCardNumber = dataUser.cards[0].creditCardNumber
					card.creditCardToken = dataUser.cards[0].creditCardToken
					this.setState({ cards: dataUser.cards, card: card, hasCard:true })
				}

				localStorage.setItem('dataUser', JSON.stringify(dataUser))

				this.setState({ dataUser: dataUser})
			}

		} else {
			this.setState({cliente: false})
		}
	}

    async aplicarCupom(){
		const codigoInfo = await getCodigoInfo(this.state.cupomDesconto, this.context.client)

		if (codigoInfo.codigo){
			const comanda = this.context.comanda
			await addVoucheComanda({id_voucher:codigoInfo.id, id_comanda: comanda.id})
			this.setState({ codigoDesconto: codigoInfo, erroDesconto: false })
		} else {
			this.setState({ erroDesconto: true })
		}
		
	}

	calculaDesconto(){
		const total = this.context.itensCarrinho.reduce((a,item) => a += item.valor * item.quantidade, 0 )+parseFloat(this.state.possuiEntrega.valor)
		const desconto = (total * (this.state.codigoDesconto.valor / 100)) * -1
		return desconto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
	}

    setDesconto(nome, valor){
		this.setState({ cupomDesconto: valor })
	}

    totalCarrinho(){
		let total = 0
		let desconto = 0
		
		total = this.context.itensCarrinho.reduce((a,item) => a += item.valor * item.quantidade, 0 )
        
        if (this.state.possuiEntrega && this.state.possuiEntrega.valor) total += parseFloat(this.state.possuiEntrega.valor)
		
		if (this.state.codigoDesconto) desconto = (total * (this.state.codigoDesconto.valor / 100))

		return (total - desconto)
	}

    mudaQuantidadeCarrinho(novoValor, index){
		const items = this.context.itensCarrinho

		if(novoValor === 'add') {
			items[index].quantidade = items[index].quantidade + 1 
		} else {
			items[index].quantidade = items[index].quantidade - 1 

			if (items[index].quantidade === 0 ) {
				items.splice(index, 1)
			}
		}

		this.context.atualizaCarrinho( items )
	}

    handlerTrocaCartao(){
		this.setState({showCartao: true})
	}

    timer() {
        let time = this.state.timer

        if (time > 0) time--
        else clearInterval(this.state.timerInterval) 

        let secs = time
        const mins = Math.floor(secs/60)
        secs %= 60
        const pretty = ( (mins < 10) ? "0" : "" ) + mins + ":" + ( (secs < 10) ? "0" : "" ) + secs
        
        this.setState({clock: pretty, timer:time}) 
    }

    async pagarPedido(pix = false){
		this.setState({loading: true, tipoPagamento: pix ? 'PIX' : 'CREDITO'})
		const total = this.context.itensCarrinho.reduce((a,item) => a += item.valor * item.quantidade, 0 ).toFixed(2)
		let comanda = this.context.comanda

		if (comanda){
			const c = await getComandaDelivery({id:comanda.id}) 
			this.context.handleSetComanda(c.data)
		} else {
			const c = await getComandaDelivery()
			comanda = c.data
			this.context.handleSetComanda(c.data)
		}
		

		const data = { 
			valor: total, 
			itens: this.context.itensCarrinho, 
			id_comanda:comanda.id 
		}

		const entrega = {
			items:this.state.possuiEntrega, 
			relations:{id_comanda:comanda.id, total: (parseFloat(total) +  parseFloat(this.state.possuiEntrega.valor)) }
		}

		entrega.items.token = this.context.client

		const dataPagamento = {
			items:{
				pagamento:{
					valor:total,
					tipo:pix ? "PIX" : "CREDITO",
				   	b_taxa: false,
				   	valor_taxa:0,
					b_taxa_delivery:true,
					valor_taxa_entrega:this.state.possuiEntrega ? this.state.possuiEntrega.valor : 0
				},
				card:this.state.card,
				id_comanda:comanda.id
			},
			token:this.context.client
		}
		
		//coloca comanda em analise
		if(!this.state.error) {
            await analise({id:comanda.id})
            await addPedido(data)
            await addDadosEntrega(entrega)
        }
		
		const pagamentoRetorno = await pagamento(dataPagamento)

		if (pagamentoRetorno.data.success) {
            if (pix) {
                const resultPIX = await getCodigoPix({items:{id_comanda:comanda.id}})

                if (resultPIX.data){
                    this.setState({chavePIX: resultPIX.data.payload})

                    const interval = setInterval(() => {
                        validaStatusPagameto({items:{id_comanda:comanda.id}}).then(status => {
                            if (status.data.status === "RECEIVED" || status.data.status === "CONFIRMED") {
                                clearInterval(interval)
                                //confirmar pagamento no xerxes
                                confirmaPagamento({id:comanda.id})
                                this.context.handleSetComanda(null)
                                this.context.atualizaCarrinho([])
                                this.context.sethistoricoCarrinho(comanda)
                            }
                        })
                    }, 1000)

                    const contdown = setInterval(() => {
                        this.timer()
                        this.setState({ timerInterval:contdown })
                    }, 1000)
                }
            } else {
                this.context.handleSetComanda(null)
                this.context.atualizaCarrinho([])
                this.context.sethistoricoCarrinho(comanda)
            }

            this.setState({loading: false})
		} else {
			this.setState({ loading: false, error: true })
		}
	}

    copiaChavePIX(e) {
        e.preventDefault()

        const copyText = this.state.chavePIX

        navigator.clipboard.writeText(copyText)
    }

    render() {
        return (
            <div>
                <div className="pos-table" style={{backgroundColor:"#DEE2E6"}}>
                    {this.context.itensCarrinho.map((item,i) =>
                        <div key={"item_"+i} className="row pos-table-row">
                            <div className="col-9">
                                <div className="pos-product-thumb">
                                    {item.imagem ? (
                                        <div className="img" style={{backgroundImage: "url(https://grubupload.s3.amazonaws.com/"+item.imagem.imagem+")"}}></div>
                                    ) : (
                                        <div className="img" style={{backgroundImage: "url(https://grubicons.s3.us-east-1.amazonaws.com/foto-padrao-grub-digital.jpg)"}}></div>	
                                    )}
                                    <div className="info">
                                        <div className="title">{item.nome.toUpperCase()}</div>
                                        <div className="title" style={{fontSize:'12px'}}>
                                            {item.variacao && item.variacao.length > 0 && (
                                                item.variacao[0].nome
                                            )}
                                        </div>

                                        <div className="single-price"> R$ {item.valor} </div>
                                        
                                        {item.opcionais && item.opcionais.length > 0 && (
                                            item.opcionais.map(i => (
                                                <div className="title" style={{fontSize:'12px'}}> Retirar {i.nome} </div>
                                            ))
                                        )}

                                        {item.adicionais && item.adicionais.length > 0 && (
                                            <>
                                                {item.adicionais.map(i => (
                                                    <div className="title" style={{fontSize:'12px'}}>  
                                                        {i.nome} {i.valor ? " - R$ " + i.valor : ""} 
                                                    </div>
                                                ))}

                                                <div className="single-price"> R$ { item.totalAdicional } </div>
                                            </>
                                        )}

                                        <div className="input-group qty">
                                            <div className="input-group-append">
                                                <Link to="#" className="btn btn-default" onClick={() => this.mudaQuantidadeCarrinho('remove', i)}><i className="fa fa-minus"></i></Link>
                                            </div>
                                            <input type="text" className="form-control" value={item.quantidade} readOnly/>
                                            <div className="input-group-prepend">
                                                <Link to="#" className="btn btn-default" onClick={() => this.mudaQuantidadeCarrinho('add', i)}><i className="fa fa-plus"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-3 total-price">{(parseFloat(item.valorTotal) * parseInt(item.quantidade)).toFixed(2)}</div>
                        </div>
                    )}
                </div>

                <div className='row mt-3 lg'>
                    <div className='col-7'>
                        {this.state.possuiEntrega && (
                            <address>
                                <strong>Endereço de entrega:</strong><br /><br />

                                <i class="fa-solid fa-location-dot"></i> {this.state.possuiEntrega.rua}, {this.state.possuiEntrega.numero}<br />
                                {this.state.possuiEntrega.bairro} 
                                {this.state.possuiEntrega.cidade && (
                                    <> 
                                        - {this.state.possuiEntrega.cidade.cidade}
                                    </>
                                )}
                                <br />
                            </address>
                        )}
                    </div>

                    <div className='col-5' style={{textAlign: "right"}}>
                        <button className="btn btn-outline-gray" onClick={() => {this.setState({ showEndereco: "endereco"})}}>
                           Trocar End.
                        </button>
                    </div>
                </div>

                {!this.state.entregaDisponivel && (
                     <div className='row'>
                        <div className="col-12 mt-2 alert alert-danger fade show">
                            Infelizmente não entregamos na sua região :(
                        </div>
                    </div>
                )}

                <hr />

                <div className='mt-3'>
                    <strong>Possui um voucher de desconto?</strong>
                    <div className='row'>
                        <div className='col-8 mt-3 mb-3'>
                            <InputText nome="Insira seu voucher aqui" setFomrData={this.setDesconto}  />
                        </div>
                        <div className='col-4 mt-3 mb-3' style={{textAlign: "right"}}>
                            <button className="btn btn-outline-gray btn-lg" onClick={(e) => {this.aplicarCupom(e)}} style={{width:"100%"}}>
                                Resgatar
                            </button>
                        </div>
                    </div>
                    {this.state.erroDesconto && (
                        <div className='row'>
                            <div className="col-12 mt-2 alert alert-danger fade show">
                                Voucher não encontrado ou expirado.
                            </div>
                        </div>
                    )}
                </div>

                <hr />

                <div className='mt-3'>
                    <strong>Escolha o tipo de pagamento:</strong>

                    <div className='row mt-3'>
                        <ul class="nav nav-tabs">
                            <li class="nav-item" style={this.state.abaCartao ? {backgroundColor:"#eeeeee", borderRadius:"5px 5px 0 0", cursor:"pointer"} : {cursor:"pointer"}}>
                                <div onClick={() => this.setState({abaCartao: true})}  class="nav-link" style={{color:"#000"}}> <i className="fa-solid fa-credit-card" style={{color:"#0078ff"}}></i> CRÉDITO</div>
                            </li>
                            <li class="nav-item" style={!this.state.abaCartao ? {backgroundColor:"#eeeeee", borderRadius:"5px 5px 0 0", cursor:"pointer"} : {cursor:"pointer"}}>
                                <div onClick={() => this.setState({abaCartao: false})} class="nav-link" style={{color:"#000"}}>  <i className="fa-brands fa-pix" style={{color:"#02bbac"}}></i> PIX</div>
                            </li>
                        </ul>
                        
                        <div style={{backgroundColor:"#eeeeee", padding: "20px"}}>
                            <div style={this.state.abaCartao ? {display:"block"} : {display:"none"}}>                        
                                {this.state.card.creditCardNumber ? (
                                    <div className='row mt-3'>
                                        <div className='col-7'>
                                      

                                        <i className="col-5 fa-solid fa-credit-card" style={{marginRight: "15px", color:"#0078FF", fontSize:"22px", position:"absolute"}}></i> 
                                            <span className='Dacos' style={{marginLeft: "35px"}}> ******* <strong>{this.state.card.creditCardNumber}</strong></span>
                                        </div>

                                        <div className='col-5' style={{textAlign: "right"}}>
                                            <button className="btn btn-outline-primary" onClick={() => {this.handlerTrocaCartao()} }>
                                                Trocar Cartão
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    
                                    
                                    <button className="col-12 mt-3 btn btn-outline-primary btn-lg me-1 mb-1" onClick={() => {this.setState({showCartao: true})}}>
                                        <i className="fa-solid fa-credit-card"></i> Incluir cartão de crédito
                                    </button>
                                )}
                            </div>

                            <div style={!this.state.abaCartao ? {display:"block"} : {display:"none"}}>
                                {this.state.chavePIX ? (
                                    <div>
                                        <p>Copie o código abaixo e utilize o <strong>PIX "Copia e Cola"</strong> no aplicativo do seu banco para fazer o pagamento:</p>
                                        
                                        <div className="form-floating mb-15px">
                                            <input className="form-control fs-15px" value={this.state.chavePIX}  name="chavepix" readOnly/>
                                            <Label name="chavepix" label="Chave PIX"/>
                                        </div>

                                        <button className="col-12 mt-3 btn btn-pix btn-lg me-1 mb-1" onClick={(e) => this.copiaChavePIX(e)}>
                                        <i className="fa-brands fa-pix"></i> Copiar código PIX
                                        </button>

                                        <p style={{margin: "10px 0 0 0"}}>Efetue o pagamento em até:</p>
                                        <i className="fa-brands fa-pix" style={{fontSize: "20px", color:"#02bbac"}}></i>
                                        <strong style={{fontSize: "20px", pading:"20px 10px"}}> {this.state.clock} </strong>
                                    </div>
                                ):(
                                    <>


                                        <p>Gere o código <strong>PIX "Copia e Cola"</strong> para concluir o pagamento.</p>
                                        {parseInt(this.totalCarrinho()) < 25 ? (
                                            <div>                                        
                                                <button className="col-12 mt-3 btn btn-outline-grey btn-lg me-1 mb-1">
                                                    <i className="fa-brands fa-pix"></i> Gerar código PIX Copia e Cola
                                                </button>
                                            </div>
                                        ):(
                                            <div>                                        
                                                <button className="col-12 mt-3 btn btn-outline-pix btn-lg me-1 mb-1" onClick={() => {this.pagarPedido(true)}}>
                                                    <i className="fa-brands fa-pix"></i> Gerar código PIX Copia e Cola
                                                </button>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <hr />

                <div className="row">
                    <div className="col-6 h6">Subtotal</div> 
                    <div className="col-6" style={{textAlign:"right"}}> <strong>{ this.context.itensCarrinho.reduce((a,item) => a += item.valor * item.quantidade, 0 ).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) } </strong></div>
                </div>
                    
                {this.state.possuiEntrega && this.state.possuiEntrega.valor ? (
                    <>
                        <div className="row">
                            <div className="col-6 h6">Taxa de entrega</div>
                            <div className="col-6" style={{textAlign:"right"}}> <strong>{ parseFloat(this.state.possuiEntrega.valor).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) } </strong></div>
                        </div>

                        {this.state.codigoDesconto && (
                            <div className="row">
                                <div className="col-8"  style={{color:"#0078FF"}}><strong> <i className="fa-solid fa-tag"></i> Descontos e promoções</strong></div> 
                                <div className="col-4" style={{textAlign:"right", fontSize:"15px", color:"#0078FF"}}> <strong>{  this.calculaDesconto()  } </strong></div>
                            </div>
                        )}

                        <div className="row">
                            <div className="col-6 h6">Total</div> 
                            <div className="col-6" style={{textAlign:"right", fontSize:"15px", color:"green"}}> <strong>{ this.totalCarrinho().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) } </strong></div>
                        </div>
                        
                    </>
                ) : (
                    <>
                        {this.state.codigoDesconto && (
                            <div className="row">
                                <div className="col-8">Descontos e promoções</div> 
                                <div className="col-4" style={{textAlign:"right", fontSize:"14px", color:"#0078FF"}}> <strong>{  this.calculaDesconto()  } </strong></div>
                            </div>
                        )}

                        <div className="row">
                            <div className="col-6">Total</div> 
                            <div className="col-6" style={{textAlign:"right", fontSize:"14px", color:"green"}}> <strong>{ this.totalCarrinho().toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) } </strong></div>
                        </div>
                    </>
                )}

                {this.state.entregaDisponivel && this.state.abaCartao && this.state.hasCard && parseInt(this.totalCarrinho()) >= 25 && (
                    <button className="col-12 mt-3 btn btn-primary  btn-lg me-1 mb-1" onClick={() => this.pagarPedido()}>
                        Efetuar pagamento
                    </button>
                )}

                {!this.state.hasCard && this.state.abaCartao && (
                    <div className='alert alert-primary fade show col-md-12 mt-3' style={{textAlign:"center"}}>
                        Para finalizar a compra precisa incluir um método de pagamento.
                    </div>
                )}
                
                {parseInt(this.totalCarrinho()) < 25 && (
                    <div className='alert alert-yellow fade show col-md-12 mt-3' style={{background: "#ffe0b3", textAlign:"center"}}>
                        Pedido mínimo para este estabelecimento é de R$ 25,00.
                    </div>
                )}

                <Offcanvas show={this.state.showCartao} onHide={() => this.setState({showCartao: false})}  placement="bottom" style={{height:"90%", padding:"20px"}}>
                    <Cartao submitCard={this.submitCard}/>
                </Offcanvas>

                <Offcanvas show={this.state.showEndereco} onHide={() => this.setState({showEndereco: false})}  placement="bottom" style={{height:"70%"}}>
					<Offcanvas.Body>
                        <Endereco submitEndereco={this.submitEndereco} possuiEntrega={this.state.possuiEntrega} mudaEndereco={this.mudaEndereco}/>
                    </Offcanvas.Body>
                </Offcanvas>

                {this.state.loading && (
				    <div className="pos-sidebar" style={{top: "unset", display:"block", height: "100%", background:"#fff", position: "fixed", zIndex:"1030", padding:"10px",right: "0", top: "0"}}>
						<Lottie animationData={lottieSegunraca} loop={true} style={{width: "100%", margin:"auto"}} />

                        {this.state.tipoPagamento === 'PIX' ? (
                            <h4 style={{textAlign:"center"}}>Aguarde uns instantes, estamos gerando o código PIX...</h4>
                        ):(
                            <h4 style={{textAlign:"center"}}>Aguarde uns instantes, estamos finalizando seu pagamento...</h4>
                        )}
						
					</div>
				)}
            </div>
        )
    }
}