import React from 'react'
import { AppSettings } from '../../config/app-settings.js'
import Carrinho from './carrinho.jsx'
import DetalhePedido from './detalhe-pedido.jsx'

import Lottie from "lottie-react"
import lottiesempedidos from "../../assets/pedidos-vazios-cozinha.json"

export default class SideBar extends React.Component {
    static contextType = AppSettings

    constructor(props) {
        super(props)

        this.state = {
            modalPedidos:false,
            modalPerfil:false,
            meuHistorico:[]
        }
    }

    render() {
        return (
            
            <div className="pos-sidebar">
                <div className="pos-sidebar-header" style={{backgroundColor: "#0078FF"}}>
                    <div className="title"> Delivery </div>
                </div>
                
                <div className="pos-sidebar-body tab-content" style={{padding:"20px 20px 90px 20px", overflow: "scroll", position:"relative"}}>
                    <strong>Detalhe do pedido:</strong>

                    {this.context.historicoCarrinho && (
                        <DetalhePedido loading={this.props.loading} />
                    )}

                    {this.context.itensCarrinho && this.context.itensCarrinho.length > 0 && (
                        <Carrinho loading={this.props.loading} />
                    )}
                    
                    {!this.context.historicoCarrinho && !this.context.itensCarrinho.length > 0 && (
                        <div className="mb-5">
                            <Lottie animationData={lottiesempedidos} loop={true} style={{height: "200px", margin:"auto"}} />
                            <h2 style={{textAlign:"center"}}>Ainda não fez seu pedido? </h2>
                            <p style={{textAlign:"center"}}>Faça seu pedido agora clicando <br /> no produto e colocando ele no seu carrinho.</p>
                        </div>
                    )}
                </div>
            </div>
            
        )
    }
}