import React from 'react'

import paraquedas from "../assets/paraquedas.json"
import Lottie from "lottie-react"
import { Link } from 'react-router-dom'

export default class QRcode extends React.Component {
	render() {
		return (
            <div className='form-floating mb-15px bg-white' style={{background:"white", padding:"10px", height:"100%", maxWidth:"500px", margin:"auto", alignContent:'center'}}>
                <Lottie animationData={paraquedas} loop={true} style={{width: "80%", margin:"auto"}} />

                <h2 style={{"textAlign":"center"}}>Você não caiu aqui de <br/><strong>paraquedas né?</strong></h2>
                <br/>
                <h5 style={{"textAlign":"center"}}>O link ou QR Code que você acessou foi desativado pelo estabelecimento.</h5>
                <br/>
               
                <h1 style={{"textAlign":"center"}}>
                <Link to="/" className="btn btn-primary btn-lg me-1 mb-1" style={{"margin":"0 auto"}}>Veja o nosso cardápio</Link> 
                </h1>

                <br></br>
                <br></br>

                 <p style={{"textAlign":"center"}}>Acredita que pode ser um erro?<br/><strong>Informe o garçom ou o dono do estabelecimento.</strong></p> 
            </div>
		)
	}
}