import React from 'react'

import Lottie from "lottie-react"
import lottiejson from "../../assets/pedido-enviado-cardapio.json"
import deliveryespera from "../../assets/delivery-espera.json"
import entregaok from "../../assets/enviando-delivery.json"
import PedidoAceitoEstabelecimento from "../../assets/pedido-enviado-cardapio.json"


export default class ComandaStatus extends React.Component {
    render() {
        return (
            <>
                {this.props.comanda.status === 'analise' && (
                    <div className='alert alert-yellow fade show col-md-12'>
                        <div style={{width:"20%", marginRight:"5px", float:"left"}}>
                            <Lottie animationData={lottiejson} loop={true} />
                        </div>
                        <p>
                            <b>Aguardando a confirmação do pedido pelo estabelecimento</b>
                            <br/><span>Pedido pendente de aprovação</span>
                        </p>
                    </div>
                )}

                {this.props.comanda.status === 'cancelada' && (
                    <div className='alert alert-danger fade show col-md-12'>
                        <div style={{width:"20%", marginRight:"5px", float:"left"}}>
                            <Lottie animationData={deliveryespera} loop={true} />
                        </div>
                        <p>
                            <b>Pedido cancelado pelo estabelecimento</b>
                            <br/><span>Obrigado por sua preferencia</span>
                        </p>
                    </div>
                )}

                {this.props.comanda.status === 'producao' && (
                     <div className='alert alert-primary fade show col-md-12'>
                       <div style={{width:"20%", marginRight:"5px", float:"left"}}>
                            <Lottie animationData={PedidoAceitoEstabelecimento} loop={true} />
                        </div>
                        <p>
                            <b>Pedido aprovado pelo estabelecimento</b>
                            <br/><span>Preparando o pedido em este momento</span>
                        </p>
                    </div>
                )}

                {this.props.comanda.status === 'caminho' && (
                    <div className='alert alert-primary fade show col-md-12'>
                        <div style={{width:"20%", marginRight:"5px", float:"left"}}>
                            <Lottie animationData={deliveryespera} loop={true} />
                        </div>
                        <p>
                            <b>Um entregador está indo <br></br>
                                coletar seu pedido. </b>
                            <br/><span>Aguarde um momento que logo estará indo até você</span>
                        </p>
                    </div>
                )}

                {this.props.comanda.status === 'entregue' || this.props.comanda.status === 'fechada' && (
                   <div className='alert alert-green fade show col-md-12' style={{color: "#052f13", background:"#b7e2c5", borderColor: "#b7e2c5"}}>
                        <div style={{width:"20%", marginRight:"5px", float:"left"}}>
                            <Lottie animationData={entregaok} loop={true} />
                        </div>
                        <p>
                            <b>Seu pedido foi entregue</b>
                            <br/><span>Obrigado por sua preferencia</span></p>
                    </div>
                )}
            </>
        )
    }
}