import React from 'react'

import { QrReader } from 'react-qr-reader'


export default class QRcode extends React.Component {

	async qrHandleScan(data) {
		if (data) {
			window.location.href = data
		}
	}
  
	render() {
		return (
            <>
                <QrReader
                    onResult={(result) => {
                        if (!!result) {
                            this.qrHandleScan(result?.text);
                        }
                    }}
                    constraints={{
                        facingMode: 'environment'
                    }}
                    videoStyle={{background: 'black', height: "100%"}}
                    videoContainerStyle={{width:'100%', background: 'black', padding:"0", height:"100%", position:"absolute"}}
                />
                <div style={{backgroundImage: 'url("/mascara.png")', backgroundPosition: 'center center', position: 'absolute', top: '0', left: '0', backgroundSize: 'cover', width:'100%', height: '100%', zIndex:'2000'}}></div>
            </>
		)
	}
}