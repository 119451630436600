import React from 'react'

export default class InputText extends React.Component {
    constructor(props) {
        super(props)

        this.handleChangeData = this.handleChangeData.bind(this)
    }

    handleChangeData = (e) => {
        e.preventDefault()
        if (this.props.returnObject){
            this.props.setFomrData(e.target)
        } else {

            let val = e.target.value

            if (this.props.decimal){

                const regex = /([0-9]|,|\.)/
                if (e.target.value === "" || regex.test(e.target.value) ) {
                    val = e.target.value.replace(",",".")
                }

            }

            this.props.setFomrData(e.target.name, val)
        }
    }

    render() {
        return (
            <input 
                id={"floatingInput_"+this.props.nome} 
                name={this.props.nome} 
                value={this.props.dadosIniciais} 
                className="form-control fs-15px" 
                onChange={(e) => this.handleChangeData(e)} 
                required={this.props.required}
                maxLength={this.props.maxlength && (this.props.maxlength)}
                placeholder={this.props.nome}
            />
        )
    }
}