import { createBrowserRouter } from "react-router-dom"

import Cardapio from './../pages/cardapio'
import PosHome from './../pages/home.js'
import PosDestaques from './../pages/destaques.js'
import Lost from './../pages/lost'


export const routes = createBrowserRouter([
  {
    path: '/',
    exact: true,
    title: 'GRUB - Cardapio',
    element: <Cardapio />
  },

  {
    path: '/dinein/:mesa',
    exact: true,
    title: 'GRUB - Cardapio',
    element: <Cardapio />
  },

  {
    path: '/delivery',
    exact: true,
    title: 'GRUB - Cardapio',
    element: <Cardapio />
  },
  
  {
    path: '/home',
    exact: true,
    title: 'GRUB - Home',
    element: <PosHome />
  },
  
  {
    path: '/destaques',
    exact: true,
    title: 'GRUB - Destaques',
    element: <PosDestaques />
  },
  
  {
    path: '/404',
    exact: true,
    title: 'GRUB - rota nao encontrada',
    element: <Lost />
  }
])