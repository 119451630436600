import React from 'react'
import { AppSettings } from '../../config/app-settings.js'

import { me, getEntrega, getEndereco } from './../../store/api.js'

import InputText    from '../../components/atoms/InputText'
import Label        from '../../components/atoms/Label'

import BuscaEndereco from '../atoms/BuscaEndereco.jsx' 

export default class Endereco extends React.Component {
    static contextType = AppSettings

    constructor(props) {
        super(props)

        this.state = {
            criaEndereco: 1,
            possuiEntregaNovoEndereco: null,
            enderecoValido:null,
            changeEnderecoInvalido:null,
            enderecos:[],
            novoEndereco:{
				items:{}
			},
        }

        this.handlerCepNovoEndereco = this.handlerCepNovoEndereco.bind(this)
        this.handlerNovoEndereco = this.handlerNovoEndereco.bind(this)
    }

    async handlerNovoEndereco(valor){
        const endereco = valor.terms
		let data = this.state.novoEndereco
        const isNumeroNaRua = endereco[0].value.split(",")

        if (isNumeroNaRua.length === 1) {
            data.items["estado"] = endereco[4].value
            data.items["cidade"] = endereco[3].value
            data.items["bairro"] = endereco[2].value
            data.items["numero"] = endereco[1].value
            data.items["rua"] 	 = endereco[0].value
        } else {
            data.items["estado"] = endereco[3].value
            data.items["cidade"] = endereco[2].value
            data.items["bairro"] = endereco[1].value
            data.items["numero"] = endereco[0].value.split(",")[1]
            data.items["rua"] 	 = endereco[0].value.split(",")[0]
        }

        const gps = await getEndereco(valor.description.replaceAll(" ","+"))
        const entrega =  await getEntrega(gps.lat, gps.lng)

        endereco.distancia 	= entrega.km
        endereco.id_zona  	= entrega.id
        endereco.tempo 		= entrega.tempo
        endereco.valor 		= entrega.valor

        data.items["lat"] 	 = gps.lat
        data.items["lng"] 	 = gps.lng

        this.setState({ data })
        this.setState({ enderecoValido: entrega ? true : false, possuiEntregaNovoEndereco: valor })	
	}

    async handlerCepNovoEndereco(name, value){
		let data = this.state.novoEndereco

	     if (value !== undefined) {
            data.items[name] = value
            this.setState({ data })
        }
	}

    async changeEndereco(endereco){
		const entrega  = await getEntrega(endereco.lat, endereco.lng)

		if(entrega) {
			endereco.id_zona = entrega.id
			endereco.distancia = entrega.km
			endereco.tempo = entrega.tempo
			endereco.valor = entrega.valor
	
			this.props.mudaEndereco(endereco)
		} else {
			this.setState({possuiEntregaNovoEndereco: false, changeEnderecoInvalido: endereco.id})
		}
	}

    async submitEndereco(){
		const data = this.state.novoEndereco
		data.token = this.context.client

		if (data.items.rua && data.items.rua.length > 3) {
            this.props.submitEndereco(data)
		}
	}

    async componentDidMount() {
        const token = JSON.parse(localStorage.getItem('cliente'))
		const usuario = await me({items: {token: token}})
        
        if (usuario.data.enderecos) {
			const enderecos = usuario.data.enderecos

            if (enderecos.length > 0) {
                this.setState({enderecos: enderecos})
            }
        }
    }
    
    render() {
        return (
            <div>
                <ul className="nav nav-pills mb-3">

                <li className="nav-item h5 m3">
                        <a href="#default-tab-1" className={this.state.criaEndereco === 1 ? "nav-link active" : "nav-link"} onClick={(e) => {e.preventDefault(); this.setState({criaEndereco: 1})} }>Meus Endereços</a>
                    </li>
                    
                    <li className="nav-item h5 mb">
                        <a href="#default-tab-1" className={this.state.criaEndereco === 0 ? "nav-link active" : "nav-link"} onClick={(e) => {e.preventDefault(); this.setState({criaEndereco: 0})} }>Novo Endereço</a>
                    </li>
                   
                </ul>
                
                {this.state.criaEndereco === 0 && (
                    <>
                        <h3 style={{textAlign:"center", fontSize:"14px"}}>Incluir novo endereço:</h3>

                        <div className="form-floating mb-15px" style={{position: "relative", height: "40px",}}>
                            <div style={{position:"absolute", zIndex:"1000", width: "100%"}}>
                                <BuscaEndereco onSelected={this.handlerNovoEndereco}/>
                            </div>
                            <Label nome="endereco" label="Digite um endereço com numero"/>
                        </div>

                        <div className="form-floating mb-15px" style={{paddingTop:"15px"}}>
                            <InputText nome="complemento" setFomrData={this.handlerCepNovoEndereco} dadosIniciais={this.state.possuiEntregaNovoEndereco ? this.state.possuiEntregaNovoEndereco.complemento : ''} />
                            <Label nome="complemento" label="Complemento ou ponto de referência"/>
                        </div>

                        <div className='row'>
                            <div className="col-md-12 mb-3">
                                {this.state.possuiEntregaNovoEndereco && !this.state.novoEndereco.items['numero'] && (
                                    <div className="alert alert-primary fade show" style={{margin:"0"}}>
                                        <p style={{textAlign:"center", margin:"0"}}> 
                                            Informe o número da rua para continuar.
                                        </p>
                                    </div>
                                )}

                                {this.state.enderecoValido === false && this.state.enderecoValido !== null && this.state.novoEndereco.items['numero'] && (
                                    <div className="alert alert-danger fade show" style={{margin:"0"}}>
                                        <p style={{textAlign:"center", margin:"0"}}> 
                                            Infelizmente não entregamos na sua região :(
                                        </p>
                                    </div>
                                )}

                                {this.state.enderecoValido && this.state.novoEndereco.items['numero'] && (
                                    <div className="alert alert-green fade show" style={{color: "#052f13", background:"#b7e2c5", borderColor: "#b7e2c5", margin:"0"}}>
                                        <p style={{textAlign:"center", margin:"0"}}> 
                                            Boas noticias, entregamos na sua região :)
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>

                        <button className="btn btn-primary btn-lg" style={{width: "100%"}} onClick={() => {this.submitEndereco()}}>
                            Salvar novo endereço
                        </button>
                    </>
                )}

                {this.state.criaEndereco === 1 && (
                    <div className="mb-3" style={{height:"25%", overflow: "hidden scroll"}}>
                        {this.state.enderecos.map((endereco) => (
                            <div className='col-12 mt-3' onClick={() => this.changeEndereco(endereco)} style={{border:"1px solid #dee2e6", borderRadius: "15px", padding:"10px",  "boxShadow":"0 0.5rem 1rem rgba(0, 0, 0, 0.075)",}}>
                                <div className='col-2' style={{ display:"inline-block"}}>
                                    {this.props.possuiEntrega && this.props.possuiEntrega.id === endereco.id ? (
                                        <p style={{marginTop: "10px", display: "inline-block", height: "18px", width: "18px", backgroundColor: "#0078FF", borderRadius: "50%"}}></p>
                                    ) : (
                                        <p style={{marginTop: "10px", display: "inline-block", border:"1px solid #333", height: "18px", width: "18px", borderRadius:"50%"}}></p>
                                    )}
                                </div>
                                <div className="col-8" style={{ display:"inline-block", "cursor":"pointer"}}>
                                    <address>
                                     <p className='h5'>{endereco.rua}, {endereco.numero} - 
                                        {endereco.bairro} - {endereco.cep}</p> 
                                    </address>
                                    {this.state.changeEnderecoInvalido === endereco.id && (
                                        <div className="alert alert-danger fade show" style={{margin:"0"}}>
                                            <p style={{textAlign:"center", margin:"0"}}> 
                                                Infelizmente não entregamos na sua região :(
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    }
}