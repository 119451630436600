import React from 'react'
import {getEnderecoSugestion} from './../../store/api.js';

export default class BuscaEndereco extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            selectOptions: [],
            endereco: ''
        }

        this.handleChangeData = this.handleChangeData.bind(this)
    }

    async handleSelected(selectedOption){
        this.props.onSelected(selectedOption)

        this.setState({selectOptions:[], endereco: selectedOption.description})
    }

    handleChangeData(e) {
        let sg = this.state.selectOptions
        const valor = e.target.value

        this.setState({endereco: valor})

        getEnderecoSugestion(valor.replaceAll(" ","+")).then((sugestao) => {
            sg = []
            const predictions = sugestao.predictions

            if (predictions && predictions.length > 0) {
                for (let i=0;  i <= predictions.length; i++){
                    if(predictions[i]){
                        sg.push({
                            value: predictions[i],
                            label: predictions[i].description.split("-")
                        })
                    }
                }

                this.setState({selectOptions:sg})
            } 
        })

        
    }

    render() {
        return (
            <>
                <div class="form-floating">
                    <input type="text" class="form-control fs-15px" value={this.state.endereco} id="floatingInput" onChange={this.handleChangeData} autoComplete="off" />
                    <label for="floatingInput" class="d-flex align-items-center fs-13px">
                        Digite um endereço com numero
                    </label>
                </div>

                {this.state.selectOptions.length ? (
                    <div style={{backgroundColor:"#FFF"}}>
                        <ul style={{listStyle: "none", padding: "10px", border: "1px solid #ccc", marginTop: "5px", borderRadius: "5px"}}>
                            {this.state.selectOptions.map((i) => 
                                <li style={{padding:'3px', borderBotton:"1px solid #000", textAlign:"left"}} onClick={() => this.handleSelected(i.value)}>
                                    <b>{i.label[0]}</b> 
                                    <span style={{display:"block"}}>{i.label[1]}</span>
                                </li> 
                            )}
                        </ul>
                    </div>
                ) : (<></>)}
            </>
        )
    }
}